import '../styles/globals.scss';

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AppContextProvider } from 'context/AppContext';
import { AppContextProvider as AppContextProviderNew } from 'context/AppContextNEW/AppContextNEW';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

import DrawerProvider from '@anchorage/backoffice/components/DrawerProvider/DrawerProvider';
import { NonceProvider } from '@anchorage/common/dist/components/SelectV2';
import { SnackbarProvider } from '@anchorage/common/dist/components/Snackbar';
import '@anchorage/common/dist/components/index.css';

import useInfoDrawer from 'hooks/useInfoDrawer';

import { SESSION_TYPE_HEADER, getSessionInfo } from 'utils/api';
// Load the error reporter and initialize it
import type { SentryEnvironments } from 'utils/sentry';
import initSentry from 'utils/sentry';
import useIsPortoApp from 'utils/useIsPortoApp';
import usePortoTheme from 'utils/usePortoTheme';

import 'normalize.css';

import '../fonts/fonts.css';
import css from './styles.module.css';

import { CSP } from './_document';

const queryClient = new QueryClient();

const connectToDevTools = process.env['NODE_ENV'] !== 'production';

const initApolloClient = () => {
  const { sessionFormType, id } = getSessionInfo();
  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        QuestionnaireNavigationItem: { keyFields: ['pageID'] },
      },
    }),
    connectToDevTools,
    uri: `/graphql/${id}`,
    // Pass along cookies
    credentials: 'same-origin',
    // This only works because there is no way to navigate between routes that
    // would produce different results for "getSessionTypeValue".
    headers: { [SESSION_TYPE_HEADER]: sessionFormType },
  });
};

interface EnvType {
  SENTRY_ENVIRONMENT?: SentryEnvironments;
  SENTRY_DSN?: string;
  SENTRY_RELEASE?: string;
}

type AppWindow = Window & {
  env?: EnvType;
};

function MyApp({ Component, pageProps }: AppProps) {
  const appWindow =
    typeof window !== 'undefined' ? (window as AppWindow) : null;
  const appWindowEnv = appWindow && appWindow.env ? appWindow.env : null;

  const [skipSSG, setSkipSSG] = useState(true);
  const [sentryInitialized, setSentryInitialized] = useState(false);
  const { basePath } = useRouter();

  const sessionInfo = getSessionInfo();

  const isPorto = useIsPortoApp();
  const client = useMemo(() => initApolloClient(), []);

  const { component: InfoDrawer } = useInfoDrawer();

  useEffect(() => {
    if (sentryInitialized || !appWindowEnv) return;

    const sentryDsn = appWindowEnv.SENTRY_DSN;
    const sentryRelease = appWindowEnv.SENTRY_RELEASE;
    const sentryEnvironment = appWindowEnv.SENTRY_ENVIRONMENT;

    initSentry({
      dsn: sentryDsn,
      release: sentryRelease,
      environment: sentryEnvironment,
    });

    setSentryInitialized(true);
  }, [sentryInitialized, appWindowEnv]);

  useEffect(() => {
    setSkipSSG(false);
  }, [skipSSG]);

  usePortoTheme({
    active: isPorto,
  });

  const faviconPath = useMemo(() => {
    return `${basePath}/${isPorto ? 'portoFavicon.ico' : 'favicon.ico'}`;
  }, [isPorto, basePath]);

  return (
    //@ts-ignore react in common is older than here. This results in an incompatibility of types
    <NonceProvider nonce={CSP.nonce} cacheKey="anchor-key">
      <Head>
        <link id="favicon" rel="shortcut icon" href={faviconPath} />
      </Head>
      <ApolloProvider client={client}>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider>
            <AppContextProvider sessionInfo={sessionInfo}>
              <AppContextProviderNew sessionInfo={sessionInfo}>
                {!skipSSG && (
                  <DrawerProvider>
                    <div className={css.app}>
                      <Component {...pageProps} />
                      <div id="modal-root" />
                      <div id="drawer-root" />
                    </div>
                    {InfoDrawer}
                  </DrawerProvider>
                )}
              </AppContextProviderNew>
            </AppContextProvider>
          </SnackbarProvider>
        </QueryClientProvider>
      </ApolloProvider>
    </NonceProvider>
  );
}

export default MyApp;
